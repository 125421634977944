/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "courses",
            "endpoint": "https://4g94sk4cxl.execute-api.ap-southeast-1.amazonaws.com/lmsspecial",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:1d74de8a-bafc-4b68-9aa9-7f0d5bbae85c",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_iGuxtTFAC",
    "aws_user_pools_web_client_id": "2c4559fnfbscl3g21dolqbj5hc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AccessCode-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Categories-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Cert-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Contributor-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "CourseCode-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "CourseOpportunity-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "FAQs-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Lectures-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "UIConfig-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "UserCourse-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "UserLecture-lmsspecial",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "courses-lmsspecial",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "lecture-resource8c84b-lmsspecial",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
